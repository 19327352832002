<template>
    <div class="dashboard-container">
        <div style="text-align: end">
            <h2 style="margin: 0">สวัสดี Admin, {{ nickname }}</h2>
            <h4 style="margin: 0">รวมยอด: {{ sum_all }} THB</h4>
            <a href="/admin/dashboard">ไปหน้าสรุป</a>
        </div>
        <div class="table-dashboard">
            <div class="dashboard-content">
                <h3>Agents - รวมยอด: {{ sum_agents }} THB</h3>
                <div class="list-container">
                    <table style="width: 100%">
                        <tr style="text-align: center; border-bottom: 1px solid #fce285;">
                            <td>เบอร์โทรศัพท์</td>
                            <td>ชื่อ</td>
                            <td>ยอดเครดิต</td>
                        </tr>
                        <tr class="table-text" v-for="{ phone_number, nickname, balance }, i  in downline_agents"
                            :key="i.key">
                            <td>{{ phone_number }}</td>
                            <td>{{ nickname }}</td>
                            <td>{{ number_comma(balance) }} THB</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="dashboard-content">
                <h3>Users - รวมยอด: {{ sum_users }} THB</h3>
                <div class="list-container">
                    <table style="width: 100%">
                        <tr style="text-align: center; border-bottom: 1px solid #fce285;">
                            <td>LINE ID</td>
                            <td>ชื่อ</td>
                            <td>ยอดเครดิต</td>
                        </tr>
                        <tr class="table-text" v-for="{ line_id, username, balance }, i  in users" :key="i.key">
                            <td>{{ line_id ? line_id : '-' }}</td>
                            <td>{{ username }}</td>
                            <td>{{ number_comma(balance) }} THB</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>


        <div class="table-dashboard">
            <div class="dashboard-content">
                <h3>รายชื่อ Staff</h3>
                <div class="list-container">
                    <table style="width: 100%">
                        <tr style="text-align: center; border-bottom: 1px solid #fce285;">
                            <td>บัญชี</td>
                            <td>ชื่อ</td>
                            <td>ยอดเงินในบัญชี</td>
                        </tr>
                        <tr class="table-text" v-for="{ id, is_main, bank, username, balance }, i  in staffs" :key="i.key">
                            <td class="bank-id" @click="set_main_account(id, bank)"><span v-if="is_main" class="badge text-bg-success">หลัก</span> {{ bank }}</td>
                            <td>{{ username }}</td>
                            <td>{{ number_comma(balance) }} THB</td>
                        </tr>
                    </table>
                </div>
            </div>


            <div class="dashboard-content">
                <h3>เปิด <select v-model="create_user">
                        <option value="user">User</option>
                        <option value="agent">Agent</option>
                        <option value="staff">Staff</option>
                    </select> ใหม่</h3>
                <div class="list-container regis-input" style="justify-content: start">
                    <div>
                        <input v-if="['user'].includes(create_user)" class="background-panel" type="text" v-model="regis_line_id" placeholder="ไลน์ไอดี" />
                        <input v-if="['staff', 'user'].includes(create_user)" class="background-panel" type="text" v-model="regis_bank" placeholder="เลขบัญชีธนาคาร" />
                        <input v-if="['staff'].includes(create_user)" class="background-panel" type="text" v-model="regis_bank_name" placeholder="ชื่อบัญชีธนาคาร" />
                        <input v-if="['agent', 'user'].includes(create_user)" class="background-panel" type="text" v-model="regis_phone_number" placeholder="เบอร์โทรศัพท์" />
                        <input class="background-panel" type="text" v-model="regis_username" placeholder="Username" />
                        <input class="background-panel" type="text" v-model="regis_password" placeholder="Password" />
                        <input v-if="['agent', 'user'].includes(create_user)" class="background-panel" type="number" v-model="regis_balance" placeholder="ยอดเงิน" />
                        <button class="highlight-primary th" @click="create_user_agent(create_user)">สร้างบัญชี</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import AdminService from '../../services/AdminService'
export default {
    name: 'dashboard-agent',
    computed: {
        sum_agents() {
            return this.number_comma(this.downline_agents.length ? this.downline_agents.map(({ balance }) => balance).reduce((a, b) => a + b) : 0)
        },
        sum_users() {
            return this.number_comma(this.users.length ? this.users.map(({ balance }) => balance).reduce((a, b) => a + b) : 0)
        },
        sum_all() {
            const agent = this.downline_agents.length ? this.downline_agents.map(({ balance }) => balance).reduce((a, b) => a + b) : 0
            const user = this.users.length ? this.users.map(({ balance }) => balance).reduce((a, b) => a + b) : 0
            return this.number_comma(agent + user)
        }
    },
    data() {
        return {
            profile: 'Admin',
            nickname: '',
            balance: 0,
            new_credit: 0,
            users: [],
            downline_agents: [],
            staffs: [],

            /* Register */
            create_user: 'user',
            regis_line_id: '',
            regis_bank: '',
            regis_bank_name: '',
            regis_phone_number: '',
            regis_username: '',
            regis_password: '',
            regis_balance: '',
        }
    },
    methods: {
        async create_user_agent(type) {
            const userObj = {
                line_id: this.regis_line_id,
                bank: this.regis_bank,
                phone_number: this.regis_phone_number,
                username: this.regis_username,
                password: this.regis_password,
                balance: this.regis_balance,
                register_type: 'Admin'
            }
            const agentObj = {
                phone_number: this.regis_phone_number,
                nickname: this.regis_username,
                password: this.regis_password,
                balance: this.regis_balance
            }
            const staffObj = {
                bank: this.regis_bank,
                bank_name: this.regis_bank_name,
                username: this.regis_username,
                password: this.regis_password,
            }

            const CreateService = {
                user: AdminService.create_user,
                agent: AdminService.create_agent,
                staff: AdminService.create_staff,
                body: {
                    user: userObj,
                    agent: agentObj,
                    staff: staffObj
                }
            }

            const res = await CreateService[type](CreateService['body'][type])
            if (res) {
                this.get_admin_profile()
                alert('สำเร็จ')
                this.regis_bank = ''
                this.regis_phone_number = ''
                this.regis_username = ''
                this.regis_password = ''
                this.regis_balance = ''
                this.create_type = 'User'
            }
        },
        number_comma(value) {
            return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        async get_admin_profile() {
            const { nickname, users, agents, staffs } = await AdminService.profile()
            this.nickname = nickname
            this.users = users
            this.downline_agents = agents
            this.staffs = staffs
        },
        async set_main_account(id, bank_id) {
            await Swal.fire({
                title: `ใช้ ${bank_id} เป็นบัญชีหลัก?`,
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ยืนยัน',
                confirmButtonColor: '#1c2530',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return await AdminService.set_main_account(id)
                        .then(() => {
                            this.get_admin_profile()
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        },
        check_agent() {
            if (!localStorage.getItem('admin')) {
                this.$router.push('/')
            }
        },
    },
    async mounted() {
        this.check_agent()
        await this.get_admin_profile()
    }
}
</script>

<style lang="less" scoped>
    h2, h4 {
        color: #fce285
    }
    .bank-id {
        cursor: pointer;
    }
    h3 {
        font-size: 16px
    }

    .regis-input {
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
            margin: 0.25rem
        }

        input::placeholder {
            color: #fce285
        }
    }

    .dashboard-container {
        padding: 0.5rem;
        flex-direction: column;
        display: flex;
    }

    .dashboard-content {
        width: 100%;
        padding: 0.5rem;
    }

    .table-dashboard {
        display: flex;
        justify-content: space-evenly;

        table {
            max-height: 20rem;
            padding: 0.5rem
        }

        .list-container {
            .table-text {
                text-align: center;
                font-size: 14px
            }

            padding: 0.5rem;
            height: 20rem;
            overflow-y: scroll;
            border: 1px solid #fce285;
            border-radius: 5px;
        }
    }

    .create-account {
        display: flex;
        justify-content: center;
        width: 100%;

        .login-input {
            display: flex;
            flex-direction: column;
        }
}
</style>
